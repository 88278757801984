<template>
  <div>
    <div class="card-toolbar mb-5">
      <router-link v-if="$can('discounts_setting.create')" to="/settings/discount/create" class="btn btn-primary font-weight-bolder">
        <v-icon>mdi-plus</v-icon>
        {{ $t('add_discount') }}
      </router-link>
        <button @click="openAdvancedSearch" class="btn btn-outline-primary font-weight-bolder ml-5" >
            <span><i class="la la-filter"></i> <span>{{$t('advanced_filter')}}</span></span>
        </button>
    </div>

    <div class="card card-custom mb-5" v-if="showAdvancedSearch">
        <div class="card-body">
            <div class="m-form m-form--fit m--margin-bottom-20">
              <div class="row">
                  <div class="form-group col-md-6">
                      <label for="name">{{$t('name')}}</label>
                      <input v-model="filters.name" type="text" id="name" class="form-control">
                  </div>
                  <div class="form-group col-md-6">
                      <label for="code2">{{$t('countries.code2')}}</label>
                      <input v-model="filters.code2" type="text" id="code2" class="form-control">
                  </div>
                  <div class="form-group col-md-6">
                      <label for="phone_code">{{$t('countries.phone_code')}}</label>
                      <input v-model="filters.phone_code" type="text" id="phone_code" class="form-control">
                  </div>
                  <div class="form-group col-md-6">
                      <label for="name">{{$t('status')}}</label>
                      <select name="" id="status" v-model="filters.status" type="text" class="custom-select">
                          <option value="">{{$t('all')}}</option>
                          <option v-for="(row, index) in status_list" :value="row.id" :key="index">{{ row.text }}</option>
                      </select>
                  </div>
                  <div class="form-group d-inline-flex col-md-6">
                      <button type="button" @click="doFilter" class="btn btn-primary mt-auto mr-2" id="m_search">
                          <span><i class="fas fa-search"></i>
                              <span>{{$t('search')}}</span>
                          </span>
                      </button>
                      <button type="button" @click="resetFilter" class="btn btn-danger mt-auto mr-2" id="m_reset">
                          <span><i class="fas fa-trash-restore"></i>
                              <span>{{$t('reset_search')}}</span>
                          </span>
                      </button>
                  </div>
              </div>
          </div>
        </div>
    </div>

    <!--begin::country-->
    <div class="card card-custom">


      <div class="card-body">
          <div class="d-flex justify-content-end mb-5">
              <custom-export-data v-if="$can('countries.export_data')" :data-list="dataList" :file-name="fileName" :fields="json_fields"></custom-export-data>
          </div>
        <v-server-table :class="'dataTable table-row-dashed'" :columns="columns" :options="options" ref="table">
          <template slot="status" slot-scope="props">
              <b-form-checkbox v-if="$can('countries.change_status')"
                  size="lg" @change="changeStatus(props.row.code2, props.row.status)"
                  v-model="props.row.status"
                  :name="'check-button'+props.row.id"
                  switch :key="props.row.id">
              </b-form-checkbox>
              <b-form-checkbox v-else
                  size="lg" :disabled="true"
                  v-model="props.row.status"
                  :name="'check-button'+props.row.id"
                  switch :key="props.row.id">
              </b-form-checkbox>
            </template>

            <template slot="actions" slot-scope="props">
              <!-- <v-btn
                icon
                color="pink"
                :to="`countries/edit/${props.row.id}`"
              >
                <v-icon small class="mr-2">mdi-pencil</v-icon>
                </v-btn> -->
                <v-icon  small class="mr-2 text-info" v-b-tooltip.hover :title="$t('edit')" v-if="$can('countries.update')" color="blue darken-2" @click="showModal(props.row)">mdi-pencil</v-icon>
                 <router-link  v-if="$can('cities.list')"
                  class="btn btn-warning font-weight-bold mr-2"
                  :to="`/settings/cities/${props.row.code2}`"
                  ><i class="icon-ld la la-globe-americas"></i>{{ $t('cities.cities') }}</router-link>
            </template>
        </v-server-table>

      </div>
    </div>
    <!--end::country-->

     <b-modal ref="modal" hide-footer :title="$t('countries.country')">

        <div class="row">
          <div class="col-md-6">
              <div class="form-group">
                  <label for="name">
                      {{ $t('countries.en_name') }}<span class="text-danger">*</span>
                  </label>
                  <input v-model="country.en_name" type="text" id="name" class="form-control" :class="validation && validation.en_name ? 'is-invalid' : ''">
                  <span v-if="validation && validation.en_name" class="fv-plugins-message-container invalid-feedback">
                      {{ validation.en_name[0] }}
                  </span>
              </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
                  <label for="name">
                      {{ $t('countries.ar_name') }}<span class="text-danger">*</span>
                  </label>
                  <input v-model="country.ar_name" type="text" min="1" id="ar_name" class="form-control" :class="validation && validation.ar_name ? 'is-invalid' : ''">
                  <span v-if="validation && validation.ar_name" class="fv-plugins-message-container invalid-feedback">
                      {{ validation.ar_name[0] }}
                  </span>
              </div>
          </div>
        </div>

        <div class="row">
          <div class="col-md-6">
            <div class="form-group">
                <label for="code_2">
                    {{ $t('countries.code2') }}<span class="text-danger">*</span>
                </label>
                <input v-model="country.code2" type="text" id="code_2" class="form-control" :class="validation && validation.code2 ? 'is-invalid' : ''">
                <span v-if="validation && validation.code2" class="fv-plugins-message-container invalid-feedback">
                    {{ validation.code2[0] }}
                </span>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
                <label for="code3">
                    {{ $t('countries.code3') }}<span class="text-danger">*</span>
                </label>
                <input v-model="country.code3" type="text" id="code3" class="form-control" :class="validation && validation.code3 ? 'is-invalid' : ''">
                <span v-if="validation && validation.code3" class="fv-plugins-message-container invalid-feedback">
                    {{ validation.code3[0] }}
                </span>
            </div>
          </div>
        </div>

        <div class="form-group">
            <label for="phone_code">
                {{ $t('countries.phone_code') }}
            </label>
            <input v-model="country.phone_code" type="text" id="phone_code" class="form-control" :class="validation && validation.phone_code ? 'is-invalid' : ''">
            <span v-if="validation && validation.phone_code" class="fv-plugins-message-container invalid-feedback">
                {{ validation.phone_code[0] }}
            </span>
        </div>

        <div class="row">
            <div class="col-md-6">
                <div class="form-group">
                    <label>{{$t('status')}}:</label>
                    <b-form-checkbox size="lg" v-model="country.status" name="check-button" switch></b-form-checkbox>
                </div>
            </div>
            <div class="col-md-6">
                <div class="form-group">
                    <label>{{$t('is_default')}}:</label>
                    <b-form-checkbox size="lg" v-model="country.is_default" name="check-button" switch></b-form-checkbox>
                </div>
            </div>
        </div>

        <div class="d-flex justify-content-end">
            <b-button class="mt-2 mr-1" variant="secondary" @click="hideModal">{{$t('close')}}</b-button>
            <b-button class="mt-2 mr-1" variant="primary" @click="save">{{$t('save')}}</b-button>
        </div>
      </b-modal>
  </div>
</template>

<script>
    import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
    import ApiService  from "@/core/services/api.service";

    export default {
        name: "index-countries",
        components: {},
        data() {
            return {
                showAdvancedSearch: false,
                filter: {
                    sortBy: 'id',
                },
                 filters: {
                    name: '',
                    status: '',
                    phone_code: '',
                    code2: '',
                },
                country : {
                    en_name: '',
                    ar_name: '',
                    code2: '',
                    code3: '',
                    status: true,
                  is_default: false,
                },
                columns: ['id','en_name','ar_name', 'phone_code', 'code2','status', 'actions'],
                validation: null,
                data: [],
                mainRoute:'settings/discounts_setting',
                routeChangeStatus : 'settings/discount_setting/change-status',
                code2: null,
                dataList : [],
                status_list: [
                    {id: 1, text: this.$t('active')},
                    {id: 0, text: this.$t('inactive')},
                ],
            }
        },
        computed: {
            json_fields: function () {
                let fields = {};
                fields[this.$t('countries.en_name')] = 'en_name';
                fields[this.$t('countries.ar_name')] = 'ar_name';
                fields[this.$t('countries.phone_code')] = 'phone_code';
                fields[this.$t('countries.code2')] = 'code2';
                fields[this.$t('status')] = 'status';
                return fields;
            },
            fileName : function () {
                return this.$t('countries.countries');
            },
            options: function () {
                let that = this;
                return {
                    texts: {
                        loadingError: that.$t('Something_went_wrong'),
                        filter: "",
                        filterBy: that.$t('Filter')+'{column}',
                        count: ' ',
                        filterPlaceholder: that.$t('Search_query'),
                        loading: that.$t('Loading')+"...",
                    },
                    headings: {
                        id: that.$t('#'),
                        en_name: that.$t('countries.en_name'),
                        ar_name: that.$t('countries.ar_name'),
                        phone_code: that.$t('countries.phone_code'),
                        code2: that.$t('countries.code2'),
                        status: that.$t('status'),
                        actions: that.$t('actions'),
                    },
                    sortable: ['name'],
                    filterByColumn: false,
                    filterable: false,
                    customFilters: [{
                        name: 'alphabet',

                    }],
                    orderBy: {'column': 'created_at'},

                    alwaysShowPerPageSelect: false,
                    perPage: 10,
                    pagination: {chunk: 5, dropdown: false},
                    skin: 'table align-middle table-row-dashed fs-5 gy-5 text-center dataTable dtr-inline',
                    sortIcon: {base: 'fas ml-2', up: 'fa-sort-alpha-up', down: 'fa-sort-alpha-down', is: 'fa-sort'},
                    perPageValues: [5, 10, 15, 25],

                    requestFunction(data) {
                        let _params = {
                            ascending: data.ascending,
                            byColumn: data.byColumn,
                            limit: data.perPage,
                            orderBy: data.orderBy,
                            page: data.page,
                            filter: data.query,
                            ...that.filters,
                        }
                        return ApiService.query("settings/discounts_setting", {..._params});

                    },
                    responseAdapter(resp) {
                        that.dataList = resp.data.data.data;
                        return {
                            data: resp.data.data.data,
                            count: resp.data.data.total,
                        }
                    }
                }
            },
        },

        mounted() {
            this.$store.dispatch(SET_BREADCRUMB, [{title: this.$t("discounts_management")}]);
        },
        methods: {
          changeStatus(id, status) {
              ApiService.patch(this.routeChangeStatus + '/' + id, {
                  status: (status ? 1 : 0),
              }).then((response) => {
                  this.$refs.table.refresh();
                  this.$successAlert(response.data.message);
              }).catch((error) => {
                  this.$errorAlert(error);
              });
            },

            showModal(data) {
                this.afterSaveCountry();
                if (!data.id) {
                    this.$refs['modal'].show();
                    this.afterSaveCountry();
                }else{
                    this.isEditing =true;
                    this.code2 = data.code2;
                    this.getData(data);
                    this.$refs['modal'].show();
                }
            },
            hideModal() {
                this.$refs['modal'].hide();
            },
            toggleModal() {
                this.$refs['modal'].toggle('#toggle-btn')
            },

            getFetch() {
                this.$refs.table.refresh();
            },
            save() {
                if (this.isEditing) {
                    this.updateCountry();
                }
            },

            updateCountry() {
                ApiService.put(`${this.mainRoute}/${this.code2}`, {
                    ...this.country,
                }).then((response) => {
                    this.$successAlert(response.data.message);
                    this.validation = null;
                    this.afterSaveCountry();
                })
                .catch((errors) => {
                    this.validation = errors.response ? errors.response.data.errors : null;
                });
            },

            getData(data) {
                this.country.ar_name = data.ar_name;
                this.country.en_name = data.en_name;
                this.country.code2 = data.code2;
                this.country.phone_code = data.phone_code;
                this.country.code3 = data.code3;
                this.country.status = data.status;
                this.country.is_default = data.is_default;

            },

            openAdvancedSearch() {
                this.showAdvancedSearch = !this.showAdvancedSearch;
            },
            doFilter() {
                this.$refs.table.refresh();
            },
            resetFilter() {
                this.filters.name = '';
                this.filters.code2 = '';
                this.filters.code3 = '';
                this.filters.status = '';
                this.filters.code2= '';

                this.$refs.table.refresh();
            },

            afterSaveCountry() {
                this.country = {
                    ar_name: '',
                    en_name: '',
                    phone_code: '',
                    code2: '',
                    code3: '',
                    status: true,
                  is_default: false,
                };
                this.hideModal();
                this.getFetch();
                this.validation = [];
            }
        },
    };
</script>
